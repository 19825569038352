<template>
  <div style="overflow: hidden;">
    <div>
      <NavBar
      style="max-height: 50px;margin-bottom: 10px;"
      :username="currentUserName"
      :pageName="pageName"
      ></NavBar>
    </div>
    <v-app>
      <v-row style="height: 96vh; overflow-y: scroll; background-color: #EFF4FF;">
        <v-col cols="2">
          <SideBar :pageName="pageName"></SideBar>
        </v-col>
        <v-col cols="10">
          <div v-if="!isLoading" align="left" style="padding-left: 64px; padding-top: 48px; display: flex; justify-content: space-between;">
            <div>
              <span style="font-weight: 800; font-size: 12pt; text-transform: uppercase; letter-spacing: 1px;">
                <h3>{{ promo_name }}</h3>
              </span>
            </div>
            <div style="display: flex; justify-content: space-around; width: 25%; margin-right: 10%">
              <!--<div style="padding: 0px 20px;">
                <v-select
                  v-model="selected_search_type"
                  :items="search_type"
                  outlined
                  style="width: 200px"
                />
              </div>
              <div style="padding: 0px 20px;">
                <v-select v-if="selected_search_type != ''"
                  v-model="selected_search_value"
                  :items="search_value"
                  outlined
                  style="width: 200px"
                />
              </div>-->
              <div style="padding: 0px 20px;" v-show="false">
                <v-text-field
                  v-model="search_promo_availability"
                  outlined
                  solo
                  height="10%"
                  dense
                  placeholder="Enter promo number"
                  style="width: 200px"
                  >
                </v-text-field>
              </div>
              <div style="padding: 0px 20px 0px 10px; margin-right: 20px;">
                <v-btn class = "primary" v-if = "search_promo_availability" style="width:100px; padding: 5px; height: 40px;" @click="search_validity" :disabled = "search_disable"><span style="font-size: 18pt;"> <v-icon>mdi-magnify</v-icon></span></v-btn>
              </div>
            </div>
          </div>
          <div align="center" style="padding-left: 64px; padding-top: 48px;" v-if="isLoading">
            <div>
              <v-progress-circular
                  indeterminate
                  color="primary"
              ></v-progress-circular>
            </div>
          </div>
            <v-row style="padding-top: 20px;" v-else>
              <v-col cols="11" style="padding-left: 64px;">
                <v-card flat style="padding: 12px 12px 0px 12px; line-height: 24px;">
                  <div style="display: flex; flex-direction: column;">
                    <div style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Promo name:</span><span style="font-weight: 600; font-size: 8pt; line-height: 24px;">{{ promo_name }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Discount value:</span><span class="promo_details_value">{{ discounted_value }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Applicable to:</span><span class="promo_details_value">{{ applicable_to }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Promo Type:</span><span class="promo_details_value">{{ promo_type }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Max Discount amount:</span><span class="promo_details_value">{{ max_discount_amount }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Total:</span> <span class="promo_details_value">{{ total_published }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Start date:</span><span class="promo_details_value">{{ promo_start_date }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Minimum spend amount:</span><span class="promo_details_value">{{ minimum_spend_amount }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Promos Used:</span> <span class="promo_details_value">{{ used_count }}</span>
                      </div>
                    </div>
                  </div>
                  <div v-if="view_more">
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">End date:</span><span class="promo_details_value">{{ promo_end_date }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Discount Scope:</span><span class="promo_details_value">{{ applicable_on }}</span>
                      </div>
                      <div align="left" class="promo_desc_class">
                        <span class="promo_details_title">Per Customer limit:</span> <span class="promo_details_value">{{ customer_limit }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" style="padding: 8px; width: 100%">
                        <span class="promo_details_title">Description:</span> <span class="promo_details_value">{{ promo_desc }}</span>
                      </div>
                    </div>
                    <div  style="display: flex; flex-direction: row; justify-content: space-evenly;">
                      <div align="left" style="padding: 8px; width: 100%">
                        <span class="promo_details_title">Discount Scope List:</span> <span class="promo_details_value">{{ applicable_list.join(", ") }}</span>
                      </div>
                    </div>
                  </div>
                  <div align="center">
                    <div class="viewMoreclass" @click="toggleDetails">
                      <div><span class="textClass">{{ toggle_text }}</span></div>
                      <div>
                        <img :class="showContentClass" src = "https://mymedicineuatimages.s3.ap-south-1.amazonaws.com/show_more.svg" />
                      </div>
                    </div>
                  </div>
                </v-card>
              </v-col>
          </v-row>
          <v-row style="padding-top: 20px;">
            <v-col cols="11" style="padding-left: 64px;">
              <div align="center"  v-if="allUsedPromos.length > 0 && !isLoading">
                <div class="fixTableHead">
                  <table class="tableClass" style="width: 100%;">
                    <tr align="center">
                      <th style="padding-left:2%">App. Number</th>
                      <th>Used on</th>
                      <th>User Name</th>
                      <th>Speciality</th>
                      <th>Doctor Name</th>
                    </tr>
                    <tr align="center" v-for="usedPromo in allUsedPromos" :key="usedPromo._id">
                      <td style="padding-left:2%">{{ usedPromo.appointment_ref_no }}</td>
                      <td>{{ usedPromo.availed_on.split("T")[0] }}</td>
                      <td>{{ usedPromo.availed_by_name }}</td>
                      <td>{{ usedPromo.availed_on_spec }}</td>
                      <td>{{ usedPromo.availed_on_doc }}</td>
                    </tr>
                  </table>
                </div>
              </div>
              <div v-else-if="!isLoading">
                No used promos for this promo family.
              </div>
            </v-col>
          </v-row>
        </v-col>
      </v-row>
    </v-app>
  </div>
</template>


<script>
import axios from "axios";
import NavBar from "../../../views/navbar.vue";
import SideBar from "../../../views/sideBar.vue";

export default {
  name: "ViewAllSuggestion",
  components: {
    NavBar,SideBar,
  },
  data() {
    return {
      pageName: "ParticularPromoFamily",
      currentUserName: "",
      currentUser: "",
      promoId: "",
      isLoading: false,
      promotion_id: "",
      promo_name: "",
      promo_start_date: "",
      promo_end_date: "",
      total_published: null,
      used_count: null,
      promo_desc: "",
      search_type: [],
      search_value: [],
      selected_search_value: "",
      selected_search_type: "",
      allUsedPromos: "",
      master_promo_details: "",
      search_promo_availability: null,
      windowHeight: window.innerHeight,
      txt: '',
      search_disable: false,
      promo_type: null,
      max_discount_amount: null,
      minimum_spend_amount: null,
      applicable_on: null,
      applicable_to: null,
      discounted_value: null,
      view_more: false,
      toggle_text: "Show More",
      showContentClass: "ShowMoreClass",
      applicable_list: null,
      customer_limit: null,
    };
  },
  mounted() {
    this.currentUser = this.$cookies.get("token");
    if (!this.currentUser) {
      this.$router.push({
        name: "AdminLogin",
      });
    } else {
      this.isLoading = true;
      var get_admin_body = {
        token : this.currentUser,
        typeOfUser : "ADMIN",
        adminType: "ADMIN"
      };
      this.promotion_id = this.$route.params['promotion_id'];
      axios.post(process.env.VUE_APP_BACKEND_URL + "/getAdmin",get_admin_body)
      .then((adminReadSuccess) =>{
        this.currentUserName = adminReadSuccess.data.data.admin_name;
        this.currentUserId = adminReadSuccess.data.data._id;
        var promo_master_body =  {
          token: this.currentUser,
          typeOfUser: "ADMIN",
          'promotionId' : this.promotion_id
        };
        axios.post(process.env.VUE_APP_BACKEND_URL + "/readPromotionsByPromotionId", promo_master_body)
        .then((promoMasterResponse) =>{
          // console.log(promoMasterResponse);
          if(promoMasterResponse.data.data)
          {
            this.master_promo_details = promoMasterResponse.data.data;
            this.promo_name = this.master_promo_details.promotion_name;
            this.promo_start_date = this.master_promo_details.valid_from;
            this.promo_end_date = this.master_promo_details.valid_till;
            this.total_published = this.master_promo_details.total_redemption_limit;
            this.customer_limit = this.master_promo_details.redemption_limit_per_user;
            this.used_count = this.master_promo_details.promotionInfoObject.length;
            this.allUsedPromos = this.master_promo_details.promotionInfoObject;
            this.promo_desc = this.master_promo_details.description;
            this.applicable_on = (this.master_promo_details.applicable_on.length > 1 ? this.master_promo_details.applicable_on.join(",") : this.master_promo_details.applicable_on[0]);
            this.applicable_to = (this.master_promo_details.applicable_to.length > 1 ? this.master_promo_details.applicable_to.join(",") : this.master_promo_details.applicable_to[0]);
            this.promo_type = this.master_promo_details.promotion_type;
            this.max_discount_amount = this.master_promo_details.max_discount_amount;
            this.minimum_spend_amount = this.master_promo_details.min_amount_spend;
            this.discounted_value = this.master_promo_details.discount;
            // this.applicable_list = this.master_promo_details.DoctorInfo;
            let doctorNames = []; let specNames = [];
            if (this.master_promo_details.DoctorInfo.length > 0) {
              doctorNames = this.master_promo_details.DoctorInfo.map((x) => {return x.doctor_name});
            }
            
            if (this.master_promo_details.specInfo.length > 0) {
              specNames = this.master_promo_details.specInfo.map((x) =>{ return x.specialization_name});
            }
            doctorNames.concat(specNames);
            this.applicable_list = doctorNames.concat(specNames);
          }
          this.isLoading = false;
        })
        .catch((PromoMasterError) =>{
          this.isLoading = false;
          // window.alert("Error: ", PromoMasterError);
          console.log(PromoMasterError);
        });
      })
      .catch((adminReadError) =>{
        this.isLoading = false;
        // window.alert("Error: ", adminReadError);
        console.log(adminReadError);
      });
    }
  },
  methods: {
    toggleDetails()
    {
      this.view_more = !this.view_more;
      if(this.view_more)
      {
        this.toggle_text = "Show Less";
        this.showContentClass = "ShowLessClass";
      }
      else
      {
        this.toggle_text = "Show More"
        this.showContentClass = "ShowMoreClass";
      }
    },
    search_validity()
    {
      this.search_disable = true;
      let promo_code = this.search_promo_availability;
      var promo_body = {
        promoCode: promo_code,
        token: this.currentUser,
        typeOfUser: "ADMIN"
      }

      axios.post(process.env.VUE_APP_BACKEND_URL+"/readPromoByCode", promo_body)
      .then((promoResponse) => {
        if(promoResponse.data.data)
          window.alert(promoResponse.data.data.availability);
        else
          window.alert("No such promo found");
        this.search_disable = false;
      })
      .catch((promoError) =>{
        console.log(promoError)
        window.alert("Error while getting promo availability");
        this.search_disable = false;
      });
    }
  },
};
</script>

<style scoped>
.textClass {
  font-size: 8pt;
}
.ShowMoreClass
{
  transform: rotateX(180deg);

}
.ShowLessClass
{
  image-orientation:  from-image;
}
.viewMoreclass {
  background-color: #E4F5FF;
  border-radius: 16px 16px 0px 0px;
  padding: 4px 10px;
  width: 15%;
  color: #1467BF;
  display: flex; 
  flex-direction: row;
  justify-content: center;
}
.promo_desc_class
{
  /*border-style: none none groove none; padding: 16px;*/
  padding: 4px;
  width: 33%;
}
.promo_details_title
{
  font-size: 8pt;
  line-height: 24px;
  color: #828282;
  padding: 4px;
}
.promo_details_value
{
  font-size: 8pt;
  line-height: 24px;
  color: #333333;
  padding: 4px;
  font-weight: 600;
}
.fixTableHead {
  overflow-y: auto;
  height: 50vh;
}
.fixTableHead thead th {
  position: sticky;
  top: 0;
}
table {
  border-collapse: collapse;        
  width: 100%;
}
td,
th {
  border: 0px;
}
td {
  padding: 2px;
  font-size: 8pt;
  font-weight: 600;
}
th {
  background-color: #1467bf;
  color: white;
  border: 0px;
  font-size: 8pt;
  font-weight: 600;
  margin-left: 10%;
  position: sticky;
  top: 0;
  z-index: 0;
  height: 40px;
}
tr {
  border-bottom: #d4eeff 1px solid !important;
}
.tableClass {
  border-spacing: 0px;
  width: 100%;
  border-collapse: collapse;
  z-index: 0;
  background-color: white;
}
table > tr > td:last-child {
    white-space: nowrap;
}
</style>